// pages/index.js
import Head from 'next/head'
import ApplicationShell from '../shared/components/ApplicationShell'
import FourOhFour from '../shared/components/FourOhFour'

export default function Home() {
  return (
    <div>
      <Head>
        <title>404: This page could not be found</title>
        <link rel="icon" href="/favicon.ico" />
      </Head>
      <ApplicationShell>
        <FourOhFour />
      </ApplicationShell>
    </div>
  )
}
